/** @jsx jsx */
import { graphql, useStaticQuery } from 'gatsby';
import { Box, Container, Heading, jsx, Text } from 'theme-ui';

import HeroImg from '../assets/images/illustrations/hero.svg';
import theme from '../gatsby-plugin-theme-ui';


const Banner = () => {
  const data = useStaticQuery(graphql`
    query {
      banner: file(relativePath: { eq: "banner.png" }) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <Box id="home" as="section" variant="section.banner">
      <Container>
        <Box sx={styles.contentWrapper}>
          <Box sx={styles.content}>
            <Heading sx={styles.title}>
              Mocking tool for your amazing projects
            </Heading>
            <Text as="p" sx={styles.text}>
              Stress-free environment for your mocking needs. Setup an environment in minutes and focus on what's matter to you.
            </Text>
            {/* <Button href="https://app.mockingbird.rest" variant="primary" sx={styles.button}>
              Explore Now
            </Button> */}
            <a href="https://app.mockingbird.rest" sx={styles.a}>
              Fly with us
            </a>
          </Box>
          <Box as="figure" sx={styles.illustration}>
            <HeroImg src={data.banner.childImageSharp.fluid} alt="banner" />
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default Banner;

const styles = {
  contentWrapper: {
    display: [null, null, null, 'grid'],
    gridTemplateColumns: [null, null, null, '0.9fr 1.1fr', 'repeat(2, 1fr)'],
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: [null, null, null, null, null, '100vh'],
    pt: [100, null, null, 120, 130, 120, 0],
  },
  content: {
    maxWidth: [507, 507, 507, 324, 450],
  },
  title: {
    fontWeight: 'bold',
    fontSize: ['30px', null, null, null, '42px', '40px', '60px'],
    lineHeight: 1.33,
    letterSpacing: '-1px',
    color: 'textSecondary',
  },
  text: {
    fontSize: ['14px', '14px', '14px', '16px', '16px', '18px'],
    lineHeight: [1.85, 1.85, 1.85, 1.85, 1.85, 1.85],
    color: 'textSecondary',
    mt: ['14px', '19px'],
    marginBottom: 20
  },
  button: {
    display: ['none', 'flex'],
    mt: [45, 45, 45, 25, 25],
  },
  a: {
    fontFamily: 'NotoSerif',
    fontWeight: 700,
    borderRadius: '5px',
    border: 0,
    backgroundColor: 'primary',
    color: theme.colors.backgroundSecondary,
    textDecoration: 'none',
    height: '50px',
    paddingLeft: 30,
    paddingRight: 30,
    width: 'fit-content',
    display: 'flex',
    alignItems: 'center',
    transition: 'background-color .3s ease',
    '&:hover': {
      bg: 'dark',
    },
  },
  clients: {
    display: 'flex',
    alignItems: 'center',
    mt: ['20px', '45px', '45px', '30px', '45px'],
    img: {
      maxWidth: ['80px', '100%', '100%', '100%'],
      '+ img': {
        ml: ['14px', '28px', '28px', '20px'],
      },
    },
  },
  illustration: {
    mt: ['30px', '30px', 0],
    mb: ['60px', '60px', 0],
    img: {
      maxWidth: [null, null, null, null, '90%', '90%', '100%'],
    },
  },
};
