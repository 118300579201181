/** @jsx jsx */
import { jsx, Box, Container } from 'theme-ui';
import SectionHeading from '../components/section-heading.component';
import UltimateFeature from '../components/cards/ultimate-feature.component';

import bulb from '../assets/images/icons/bulb.png';
import dart from '../assets/images/icons/dart.png';
import rocket from '../assets/images/icons/rocket.png';
import trophy from '../assets/images/icons/trophy.png';

const data = [
  {
    id: 1,
    icon: rocket,
    title: 'Rocket start',
    description: 'Don\'t wait to create awesome products.',
  },
  {
    id: 2,
    icon: dart,
    title: 'Focus',
    description: 'Focus on what you want to create, not on side-tools or solutions.',
  },
  {
    id: 3,
    icon: bulb,
    title: 'No side hassle',
    description: 'We already have all set up for you.',
  },
  {
    id: 4,
    icon: trophy,
    title: 'Fast prototyping',
    description: `Reach your MVP faster!`,
  },
];

const UltimateFeatures = () => {
  return (
    <Box as="section" id="ultimate-feature" variant="section.ultimateFeature">
      <Container>
        <SectionHeading
          sx={styles.heading}
          title="Never stop develop"
          description="Focus only on the meaning, we take care of your requests."
        />
        <Box sx={styles.features}>
          {data?.map((item) => (
            <UltimateFeature key={item.id} data={item} />
          ))}
        </Box>
      </Container>
    </Box>
  );
};

export default UltimateFeatures;

const styles = {
  heading: {
    marginBottom: [60, 60, 60, 80],
  },
  features: {
    gap: ['35px 60px', 60, 60, 40, 30, 60],
    display: ['grid', 'grid'],
    gridTemplateColumns: [
      'repeat(1, 1fr)',
      'repeat(1, 1fr)',
      'repeat(1, 1fr)',
      'repeat(2, 1fr)',
      'repeat(4, 1fr)',
    ],
  },
};
