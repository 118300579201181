/** @jsx jsx */
import { Box, Container, jsx } from 'theme-ui';

import Accordion from '../components/accordion/accordion.component';
import SectionHeader from '../components/section-heading.component';

const faqs = [
  {
    title: 'What Mockingbird do?',
    contents: (
      <div>
        Mockingbird provide an almost effortless environment to mock your api.
      </div>
    ),
  },
  {
    title: 'Are my mocks data available to everyone?',
    contents: (
      <div>
        Absolutely no. We not expose any public api with your data in it. Besides a complicated url (and your custom path), every endpoint is protected with and api key that you can destroy and regenerate every time you want.
      </div>
    ),
  },
  // {
  //   title: `Website response taking time, how to improve?`,
  //   contents: (
  //     <div>
  //       Get your blood tests delivered at the home collect a sample from
  //       management news. Get your blood tests delivered at the home collect a
  //       sample from management news. Get your blood tests delivered at the home
  //       collect a sample from management news. Get your blood tests delivered at
  //       the home.
  //     </div>
  //   ),
  // },
  // {
  //   title: `New update fixed all bug and issues`,
  //   contents: (
  //     <div>
  //       If you’re looking to hunt a unicorn, but don’t know where to begin, try
  //       Lake Superior State University in Sault Ste. Marie, Michigan. Since
  //       1971, the university has issued permits to unicorn questers.
  //     </div>
  //   ),
  // },
];
export default function Faq() {
  return (
    <Box as="section" id="faq" variant="section.faq">
      <Container>
        <SectionHeader
          title="Do you have any question?"
          description="We are happy to answer your questions!"
        />
        <Box
          sx={{
            display: 'flex',
            width: ['100%', null, null, '650px', '745px'],
            flexDirection: 'column',
            mx: 'auto',
            my: -4,
          }}
        >
          <Accordion items={faqs} />
        </Box>
      </Container>
    </Box>
  );
}
