import React from 'react';

import Layout from '../components/layout.component';
import SEO from '../components/seo.component';
import Banner from '../sections/banner.component';
import Faq from '../sections/faq.component';
import Features from '../sections/features.component';
import IntroVideo from '../sections/intro-video.component';
import Pricing from '../sections/pricing.component';
import UltimateFeatures from '../sections/ultimate-features.component';
import UsefulFeatures from '../sections/useful-features.component';
import Widgets from '../sections/widgets.component';
import { GlobalTheme } from '../theme/global-theme';
import '../theme/global.css';

export default function IndexPage() {
  return (
    <Layout>
      <SEO />
      <GlobalTheme />
      <Banner />
      <UltimateFeatures />
      {/* <Features /> */}
      {/* <IntroVideo /> */}
      <UsefulFeatures />
      {/* <Widgets /> */}
      <Pricing />
      <Faq />
    </Layout>
  );
}
